import React from "react";

const AboutPage = () => {
  return (
    <div>
      <p>You are inside the About Us</p>
      <p>Only Logged in users can access this</p>
    </div>
  );
};

export default AboutPage;
